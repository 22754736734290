angular.module('fingerink')
    .service('sendSelectTypeModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                templateUrl: 'modals/sendSelectTypeModal.tpl.html',
                controller: 'sendSelectTypeModalCtrl',
                controllerAs: 'controller',
                animation: false,
                backdrop: 'static'
            });

            return this.modalInstance;
        };

    })
    .controller('sendSelectTypeModalCtrl', function viewSignatureModalCtrl($scope, $uibModalInstance, session) {
        var that = this;
        $scope.close = (r) => $uibModalInstance.close(r);
        
        that.credits = session.getRemainingCredits();
        

        that.closeModal = function () {
            $uibModalInstance.close();
        };
    });
